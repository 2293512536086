<template>
    <multiselect
        class="select-option"
        :class="{
            'select-option--invalid': state !== null && !state,
            'select-option--valid': state !== null && state 
        }"
        :label="$attrs.option_text"
        :track-by="$attrs.option_value"
        :options="options"
        :value="value"
        :show-labels="false"
        :loading="loading"
        :placeholder="$t('Search')"
        :disabled="disabled"
        @input="updateValue"
    >
        <template slot="noOptions">
            {{ $t('No options available') }}
        </template>
        <template slot="noResult">
            {{ $t('There is no option that matches your search') }}
        </template>
    </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
    components: { Multiselect },
    props: {
        source: { type: [Array, String], default: null },
        value: { type: Object, default: null },
        state: { type: Boolean, default: null },
        disabled: { type: Boolean, default: false }
    },
    data() {
        return {
            loading: true,
            options: [],
            placeholder: this.$t('Choose an option'),
        };
    },
    watch: {
        source() {
            this.getOptions();
        },
    },
    mounted() {
        this.getOptions();
    },
    methods: {
        async getOptions() {
            try {
                if (!this.source) return [];
                this.loading = true;
                const { data } = await this.axiosAccount.get(this.source);
                this.options = !this.sourceData ? data : data[this.sourceData];
                if (this.$attrs.option_value === 'code' && this.$attrs.name === 'state') {
                    this.options = this.options.map(item => {
                        if (typeof item.code === 'string') return item;

                        item.code = item.code['2digit'] || item.code['1digit'] || item.code['3digit'] || '';
                        return item;
                    });
                }

                this.$emit('load-options', {
                    options: this.options,
                    attrs: { ...this.$attrs, ...this.$props },
                });

                return data;
            } catch (error) {
                return [];
            } finally {
                this.loading = false;
            }
        },
        updateValue(event) {
            this.$emit('input', event)
            this.$emit('change');
        }
    },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
    .select-option {
        &--invalid {
            .multiselect__tags {
                border-color: #fa2739 !important;
            }
        }

        &--valid {
            .multiselect__tags {
                border-color: #81b23a !important;
            }
        }

        .multiselect {
            &__tags  {
                padding: 0.375rem 0.75rem;
                border: 1px solid #ced4da;
            }

            &__placeholder {
                padding: 0;
                margin-top: 0;
                margin-bottom: 0;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
            }

            &__single {
                padding: 0;
                margin-top: 0;
                margin-bottom: 0;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
            }


            &__input {
                margin-bottom: 0;
            }
        }

        &.multiselect--disabled {
            background-color: transparent;
            opacity: 1;
    
            .multiselect__select {
                background-color: transparent;
            }
    
            .multiselect__tags {
                background: #e9eaf2;
            }
            
            .multiselect__single {
                background-color: transparent;
            }
        }
    }

</style>
