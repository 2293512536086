export default {
    methods: {
        setValueField(field, obj) {
            const formConfig = [...this.formConfig];
            formConfig.forEach((section, index) => {
                Object.entries(section.form).forEach(([key, value]) => {
                    if (key === field && formConfig[index].form[key]) {
                        formConfig[index].form[key] = { ...value, ...obj };
                    }
                });
            });
            this.formConfig = formConfig;
        },
        getFormConfigField(field) {
            try {
                return this.formConfig.map(section => section.form[field]).filter(x => x)[0];
            } catch (error) {
                return {};
            }
        },
        mustache(string, data = {}) {
            return Object.entries(data).reduce((res, [key, value]) => res.replace(new RegExp(`{{\\s*${key}\\s*}}`, 'g'), value), string);
        },
        convertFileToBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },
    },
};
